@mixin placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}
  ::placeholder               {@content}  
}

@mixin flat-shadow() {
  box-shadow: 0 0 4px $gray;
}

@mixin smooth-shadow() {
  box-shadow: 1px 2px 4px 0px hsla(0,0%,65%,.41);
}

@mixin font-stroke() {
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-text-stroke: .25px #2c2c2c;
}

