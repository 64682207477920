// Colors
$color-primary: #000;
$color-secondary: #E25F91;
$color-highlight: #F9F9F9;
$white: #FFF;
$white-darker: #F8F8F8;
$creme: #F2EDED;
$black: #000;
$black-trans: rgba($black, .8);
$pink: #E25F91;
$gray: #D5D5D5;
$grey: #999999;
$darkgrey: #414141;
$darker-grey: #636363;
$light-grey: #efefef;

// Fonts
$font-header: 'Open Sans', 'OpenSansFallback', sans-serif;
$font-body: 'Montserrat', 'MontserratFallback', sans-serif;
$text-dark: #2C2C2C;
$text-dark-light-trans: rgba($text-dark, .9);
$text-dark-trans: rgba($text-dark, .6);
$text-grey: #A6A6A6;
$text-darker: #7E7D7D;

$first-bg: #F6F6F6;
// Border, text color most pages
$border-color: #D5D5D5;
$border-light: 1px solid rgba(213, 213, 213, 0.4);
$text-color: rgba($text-dark, .85);

$avatar-width: 75px;
$avatar-margin: 15px;
$header-height: 80px;
$header-height-mobile: 55px;

$placeholder-grey: #8c8c8c;
// global colors
$footer-bg: #131313;
//Breakpoints
$small: 480px;
$medium: 768px;
$large: 1024px;
$xlarge: 1220px;
$xxlarge: 1300px;

