// Breakpoints *specific* for banners

$banner-xs: 759px;

$banner-sm-start: 760px;
$banner-sm-end: 1279px;

$banner-md-start: 1280px;
$banner-md-end: 1364px;

$banner-lg-start: 1365px;
$banner-lg-end: 1479px;

$banner-xl: 1480px;

.banner {
  text-align: center;

  &.above-footer-banner {
    text-align: center;
    margin: 25px 0;
    display: none;

    @media (min-width: 840px) {
      display: block;
    }
  }
}

.left-skyscraper {
  position: absolute;
  left: -170px;
  display: none;
  @media (min-width: $xxlarge) {
    display: block;
  }
}

.banner img,
.ads-tag-container img {
  text-align: center !important;
  float: none !important;
  margin: auto !important;
  max-width: 100% !important;
}

.ads-ad {
  clear: both;
  padding: 0;
  margin: 0 auto;
}

.banner-target-rx {
  min-height: 300px;
}

.desktop-top-banner {
  display: none;
}

.mobile-top-banner {
  min-height: 300px;
  width: 320px;
  margin: 20px auto 0;
  display: block;
}

.mobile-center-banner {
  padding: 25px 0 0;
}

@media (min-width: $medium) {

  .desktop-top-banner {
    min-height: 300px;
    display: block;
  }

  .mobile-center-banner,
  .mobile-top-banner {
    display: none;
  }
  #mobile-user-menu, .sidr {
    display: none !important;
  }
}

.banner.left-skyscraper.banner.top-banner-fixed {
  position: fixed;
}

.topic .ads-tag-container {
  width: 100%;
  text-align: center !important;
  overflow: hidden;
  float: right;
}

body.find {
  .ads-tag-container {
    background: none;
  }
}

@media (max-width: $medium) {

  .col {
    .ads-tag-container {
      margin-left: -10px !important;
      margin-right: -10px !important;
      width: 100% !important;
    }
  }
}

.forum .content_container__content {

  .new-topic,
  .topic {

    .topic__item {
      padding: 17px 8px 17px 18px;
    }
  }
}

.above-footer-banner {
  padding: 5px;
  margin: 0 auto;
  max-width: 300px;
  text-align: center;
  overflow: hidden;
  @include susy-breakpoint($large) {
    max-width: 100%;
  }
}
