.header__admin {
  a {
    text-align: center;
    padding: 5px 0;
    display: block;
    text-decoration: none;
    font-family: $font-header;
    color: $text-dark;
  }
}

.guest__contain {
  @include susy-breakpoint($medium) {
    width: 100%;
    padding:0 0 15px 0;
    overflow: auto;
  }
}

body .featured-holder__header {
  width: 100%;
  height: 50px;

  background: white;
  box-shadow: 0 3px 4px 0 rgba(177, 177, 177, 0.14);
  
  position: fixed;
  top: 0;
  margin: 0;
  
  // prevent take-over from taking this part over.... yep it just got worse.
  z-index: 2111111111;

  .profile {
    transition: all 350ms ease;
    
    width: 36px;
    height: 50px;
    color: $black;
    border-left: 1px solid rgba(255, 255, 255, 0.3);

    float: right;
    
    margin: 0;
    padding: 0;
    
    box-shadow: none;
    text-decoration: none;
      
    z-index: 12;

    > a {
      color: $black;
      display: inline-block;
      line-height: 20px;
      width: 100%;

      @include susy-breakpoint($large) {
        color: $white;
        text-decoration: none;
      }
    }

    .account-label {
      display: none;
      float: right;
      font-family: "Open Sans", sans-serif;
      font-weight: 700;
      margin-top: 0;
      max-width: 80%;
    }
    @include susy-breakpoint($large) {
      .account-label {
        display: inline-block
      }
    }

    i {
      display: inline-block;
      font-size: 1.4em;
      padding: 13px 6px;
      @include susy-breakpoint($large) {
        font-size: 1.2em;
        padding: 0;
      }
    }
  }

  @include susy-breakpoint($large) {
    background: black;
    border-bottom: 30px solid white;

    .profile {
      position: relative;
      box-sizing: border-box;
      
      background: $black;
      color: $white;
      
      width: 125px;
      height: 50px;
      margin: 0;
      z-index: 33;
      padding: 16px 5px;

      &:before {
        content: "";
        background: #e25f91;
        width: 100%;
        height: 0px;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: -1;
        transition: all .35s ease;
        transform: translateY(100%);
      }

      &:hover {
        &:before {
          height: 5px;
          bottom: 5px;
        }
        .profile__drop {
          display: block;
        }
      }

      i {
        display: inline-block;
        vertical-align: text-bottom;
      }

      a {
        span {
          display: inline-block;
          font-family: $font-body;
          text-transform: uppercase;
          letter-spacing: 1px;
          font-weight: 300;
          font-size: .88em;
        }
      }
    }
  }

  .profile__drop {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 99;
    background: $white;
    padding: 0 0;
    box-shadow: 1px 4px 4px 0 rgba(0,0,0,0.05);
    transition: all 500ms ease;

    @include susy-breakpoint($large) {
      padding: 0 15px;
    }

    span {
      font-size: 12px;
      text-transform: uppercase;
      text-align: center;
      display: block;
      padding: 15px 0;
      width: 230px;
      font-weight: 600;
      border-bottom: 1px solid #F1F1F1;
      box-sizing: border-box;

      &:last-of-type {
        border-bottom: 0;
      }

      a {
        transition: all 200ms ease;
        text-decoration: none;
        color: #999;
        &:hover {
          color: black;
        }
      }
    }
  }
}

.header__hero {
    @include span(12);

    float: none;
    display: block;
    overflow: hidden;
    margin-bottom: 10px;
    margin-top: 5px;
    img {
      width: 100%;
      height: auto;
    }
}

header {
  @include container(100%);
  @include smooth-shadow;

  background: $white;
  height: 50px;
  z-index: 10;
  padding: 0;
  position: relative;
  width: 100%;
  margin: 0 auto;
  display: block;
  box-sizing: border-box;

  &:before {
    content: '';
    position: fixed;
    transform: translateX(-100%);
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: rgba(0,0,0,0.44);
    transition: all 350ms ease;
    z-index: 4;
  }

  .header__block {
    @include span(6 of 12);

    &.logo {
      a {
        display: block;
        height: 50px;
        padding: 10px 0;
        box-sizing: border-box;
        img {
          height: 100%;
          width: auto;
        }
      }
    }

    &.header__block--navigation {
      text-align: right;
      height: 50px;
      position: relative;

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        height: auto;

        li {
          display: inline-block;
          padding: 15px 7px;
          height: 100%;
          box-sizing: border-box;

          &.menu-item-has-children {
            position: relative;
          }

          a {
            color: $text-dark;
            text-decoration: none;
            i {
              transition: all 300ms linear;
              font-size: 1.3em;
              &.icon-Profile {
                font-weight: 600;
              }
            }
          }

          &:last-of-type {
            padding-right: 0;
          }

          &.admin__action {
            i {
              color: $color-secondary;
            }
          }

          &:hover {
            cursor: pointer;
            i {
              color: $color-secondary;
            }
          }
        }
      }
    }
  }

  .account {
    display: none;
  }

  .search-toggle {
    box-sizing: border-box;
    color: $black;
    
    float: left;
    font-size: 18px;

    padding: 16px 11px 12px;
    margin: 0 0;
    
    i {
      margin-right: -6px;
    }

    &:hover {
      color: $black;
      cursor: pointer;
    }

    @include susy-breakpoint(($large)) {
      position: relative;
      border-left: 1px solid rgba(255, 255, 255, 0.3);
      color: $white;
      overflow: hidden;
      z-index: 29;

      i {
        margin-right: 0px;
      }

      &:before {
        content: '';
        background: $color-secondary;
        width: 100%;
        height: 5px;
        position: absolute;
        left: 0px;
        bottom: 0px;
        z-index: -1;
        transition: all 350ms ease;
        transform: translateY(100%);
      }

      &:hover {
        color: white;
        &:before {
          transition: all 350ms ease;
          transform: translateY(0%);
        }
      }
    }
  }

  .menu-toggle {
    box-sizing: border-box;

    display: inline-block;
    width: 36px;
    height: 50px;
    padding: 15px 10px;
    
    z-index: 28;

    span {
      width: 16px;
      height: 2px;
      background: black;
      border-radius: 2px;
      position: absolute;
      transition: all 350ms ease;

      &:nth-child(1) {
        transform: translateY(8px) rotate(0deg);
      }
      &:nth-child(2) {
        opacity: 1;
      }
      &:nth-child(3) {
        transform: translateY(16px) rotate(0deg);
      }
    }
  }

  .site-branding {
    position: relative;
    a {
      text-decoration: none;
    }
    .logo-white {
      display: inline-block;
      width: 82px;
      padding: 11px 6px;
      position: fixed;
      transform: translateX(750%);
      left: 12%;
      z-index: 26;
      transition: all 350ms ease;
    }
    .logo {
      display: inline-block;
      width: 82px;
      padding: 11px 6px;
      position: relative;
    }
    &:before {
      content: '';
      background: black;
      position: fixed;
      height: 50px;
      width: 100%;
      left: 0;
      transform: translateX(100%);
      transition: all 350ms ease;
      z-index: 1;
    }
  }
  
  .main-navigation {
    position: fixed;
    top: 50px;
    padding-bottom: 100px;

    background: #fff;
    height: calc(100% - 50px);    
    width: 100%;
    overflow: auto;
    transform: translateX(100%);
    transition: all 350ms ease;
    z-index: 12;

    ul {
      padding: 0;
      margin: 0;
      li {
        width: 100%;
        display: inline-block;
        border-bottom: 1px solid rgba(0,0,0,0.2);
        a {
          text-decoration: none;
          font-size: 14px;
          letter-spacing: 0.02rem;
          text-transform: uppercase;
          transition: all 0.25s ease;
          display: inline-block;
          color: rgba(0,0,0,0.8);
          padding: 20px 20px;
          box-sizing: border-box;
          font-family: $font-header;
          font-weight: bold;
          width: 100%;
        }
        .sub-toggle {
          position: absolute;
          display: block;
          right: 0;
          top: 0;
          height: 54px;
          width: 26%;
          font-weight: lighter;
          padding: 15px 3px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          font-size: 24px;
          text-align: center;
          &:before {
            content: "\f067";
            font-family: "FontAwesome" !important;
          }
        }
        .sub-menu {
          padding: 0;
          display: none;
          li {
            background: #F9F9F9;
          }
          li:last-child {
            border-bottom: none;
          }
          li:first-child {
            border-top: 1px solid rgba(0,0,0,0.2);
          }
        }
        &.menu-item-has-children {
          ul.sub-menu {
            display: block;
          }
        }
      }
    }
    .social-links {
      margin: 20px;
      li {
        display: inline-block;
        border-bottom: none;
        width: 36px;
        height: 36px;
        margin-right: 12px;
        border-bottom: none;
        border-radius: 2.4px;
        padding: 0;
        a {
          padding: 8px 10px;
          i {
            font-family: 'FontAwesome' !important;
            &:before {
              color: white;
              font-size: 20px;
            }
          }
        }
        &.Pinterest {
          background: #DB4141;
          i:before {
            content: "\f231";
          }
        }
        &.Instagram {
          background: #125688;
          i:before {
            content: "\f16d";
          }
        }
        &.Twitter {
          background: #55ACEE;
          i:before {
            content: "\f099";
          }
        }
        &.Facebook {
          background: #3A5A98;
          i:before {
            content: "\f09a";
          }
        }
      }
    }
  }

  .side-navigation {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 28; // copy of .menu-toggle
  }

  // desktop version
  @include susy-breakpoint($large) {
    background: $black;
    height: 50px;
    padding: 0 20px;

    .social-list {
      display: block;
    }

    .site-branding {
      float: left;
      
      .logo {
        display: none;
        &.logo-white {
          display: inline-block;
          left: 0;
          transform: translateX(0%);
        }
      }
    }

    .main-navigation {
      position: absolute;
      top: 0;
      left: 130px;

      background: black;
      float: left;
      height: auto;
      width: 60%;
      padding: 0;

      overflow: visible;
      transform: translateX(0);
      z-index: 28;

      .menu {
        width: 100%;

        li {
          border-bottom: none;
          width: auto;
          float: left;
          height: 50px;

          &.menu-item-has-children {
            ul.sub-menu {
              display: none;
            }

            &:hover {
              ul.sub-menu {
                display: inline-block;
              }
            }

            ul.sub-menu {
              position: absolute;
              width: 194%;
              z-index: 1399;
              background: white;
              height: 30px;
              top: 50px;
              left: -130px;

              li {
                display:inline-block;
                background: none;
                border: none;
                a {
                  padding: 6px 12px 7px;
                  color: grey;
                  font-size: 12px;
                  font-weight: 600;
                  background: none;
                  height: auto;
                }

                &:hover {
                  a {
                    &:before {
                      bottom: -3px;
                    }
                  }
                }
              }
            }
          }

          a {
            color: white;
            font-weight: bold;
            font-size: 15px;
            width: 100%;
            padding: 15px 12px;
            height: 50px;
            overflow: hidden;
            position: relative;
            display: inline-block;
            &:before {
              content: '';
              background: $color-secondary;
              width: 100%;
              height: 5px;
              position: absolute;
              left: 0;
              bottom: -5px;
              z-index: -1;
              transition: all 250ms ease;
            }
            &:hover {
              &:before {
                bottom: 0;
              }
            }
          }

          &.current-menu-item {
            a {
              &:before {
                height: 100%;
                bottom: 0;
              }
            }
          }
        }
      }
    }

    .menu-toggle {
      display: none;
    }

    .account {
      display: none;
    }
  }
}

// active mobile menu state
body.body--mobile-active header {
  &:before {
    transform: translateX(0%);
  }

  .main-navigation {
    transform: translateX(12%);
    @include susy-breakpoint($medium) {
      transform: translateX(42%);
    }
    @include susy-breakpoint($large) {
      transform: translateX(0);
    }
  }

  .menu-toggle {
    position: fixed;
    right: 0;

    span {
      background: white;
      &:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:nth-child(3) {
        transform: translateY(8px) rotate(-45deg);
      }
    }
  }
  .site-branding {
    .logo-white {
      transform: translateX(-84%);
      padding-right: 20px;
    }
    &:before {
      transform: translateX(12%);
    }
    @include susy-breakpoint($medium) {
      &:before {
        transform: translateX(42%);
      }
      .logo-white {
        transform: translateX(-50%);
      }
    }
  }
}

.user {
  @include smooth-shadow;
  width: 100%;
  position: relative;
  padding: 15px 7px;
  box-sizing: border-box;
  height: 60px;
  background: #FFFFFF;
  z-index: 9;
  @media (min-width: $medium) {
    @include span(12);
    margin-top: 5px;
    margin-bottom: 0px;
  }
  .user__block {
    height: 30px;
    &.avatar {
      @include span(8 of 12);
      @media (min-width: $medium) {
        @include span(3 of 12);
      }
      img {
        height: 100%;
        width: auto;
        vertical-align: top;
        border-radius: 3px;
      }
      .user__name {
        font-family: $font-header;
        font-size: .86em;
        margin-left: 7px;
        font-weight: 700;
        color: $text-dark;
        position: relative;
        top: 4px;
      }
    }
  }
}

.user__block.actions {
  @include span(4 of 12);
  text-align: right;
  @media (min-width: $medium) {
    @include span(9 of 12);
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    height: 100%;
    li {
      display: inline-block;
      margin: 0 5px;
      line-height: 30px;
      vertical-align: middle;
      &.replies, &.pijler {
        display: none;
        @include susy-breakpoint($medium) {
          display: inline-block;
        }
      }
      &.topic {
        box-shadow: none;
      }
      a {
        color: #636363;
        text-decoration: none;
        transition: all 250ms ease;
        &:hover {
          color: $color-secondary;
        }
        p {
          display: none;
          margin: auto;
          font-weight: 600;
          font-size: 12px;
          font-family: $font-header;
          @include susy-breakpoint($medium) {
            display: inline-block;
          }
        }
        &.inbox__link {
          position: relative;
          span.counter {
            width: 12px;
            height: 12px;
            min-width: 0;
            line-height: 13px;
            left: 5px;
            top: -5px;
            text-indent: 1px;
            text-align: center;
          }
        }
        i {
          font-size: 1.1em;
          padding-right: 4px;
          position: relative;
          top: 2px;
        }
      }
      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }
}

li.modpanel {
  position: relative;
}

.action__inbox,
.action__new-topic {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.action__new-topic {
  margin-top: -7px;
}

.user__name a {
  text-decoration: none;
  color: inherit;
}

.guest {
  @include smooth-shadow;
  @include span(12);
  background: $white;
  .guest__block {
    padding: 10px 14px;
    @media(min-width: $medium) {
      @include smooth-shadow;
      padding: 15px;
    }
    .guest__button {
      font-weight: 600;
      font-size: .9em;
      text-align: center;
      width: 100%;
    }
    h1 {
      font-family: $font-header;
      font-size: .95em;
      text-transform: uppercase;
      font-weight: 800;
      color: $text-dark;
    }
    p {
      font-family: $font-header;
      font-weight: 400;
      color: $text-dark;
      font-size: .90em;
      line-height: 23px;
      @include font-smoothing();
    }
    .guest__disclaimer {
      span {
        font-family: $font-header;
        font-size: .69em;
        color: $text-grey;
        display: block;
        letter-spacing: .07px;
        line-height: 15px;
        font-weight: 300;
        @include font-smoothing();
        a {
          text-decoration: underline;
          color: inherit;
          &:hover {
            color: $color-secondary;
          }
        }
      }
    }
    .guest__register {
      .button {
        margin: 15px 0;
      }
      .guest__link {
        text-align: center;
        display: block;
        font-family: $font-header;
        font-size: .85em;
        @include font-smoothing();
        font-weight: 400;
        a {
          color: $color-primary;
          font-weight: 600;
          text-decoration: underline;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.moderator__panel {
  @include smooth-shadow;
  @include span(12);
  margin-bottom: 15px;
  background: $white;
  display: none;
  padding: 20px 0 45px 0;
  position: relative;
  h3 {
    margin-top: 0;
  }
  .panel__wide {
    @include span(12);
    width: 100%;
    background: $color-secondary;
    margin-top: 20px;
    padding: 3px 10px;
    box-sizing: border-box;
    text-align: left;
    margin-left: 0;
    margin-right: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    a {
      font-family: $font-header;
      font-size: 13px;
      text-decoration: none;
      color: $white;
      font-weight: 500;
      i {
        margin-right: 5px;
      }
    }
  }
  .panel__left {
    @include span(8);
  }
  .panel__right {
    @include span(4);
  }
  .panel__item {
    @include container(100%);
    font-size: 13px;
    font-weight: 400;
    font-family: $font-header;
    padding: 5px 0;
    a {
      color: $black;
      text-decoration: none;
    }
    span {
      margin-right: 10px;
      float: left;
      &.item__forumname {
        width: span(2);
        font-weight: 600;
      }
      &.item__name {
        width: span(4);
      }
      &.item__reason {
        width: span(3);
      }
      &.item__reportedby {
        width: span(1);
      }
      &.item__time {
        width: span(2);
      }
    }
  }
  .panel__notice {
    font-size: 13px;
    font-weight: 400;
  }
}

body #sanoma-consent-bar-mobile {
  margin-top: 80px !important;
}
